var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
let isDownloadAllowed = true;
export function downloadImage(url) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!isDownloadAllowed)
            return;
        isDownloadAllowed = false;
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        const filename = `BACK_TO_WONDERLAND_AUX_GALERIES_LAFAYETTE_${day}_${month}_${year}_${hours}_${minutes}_${seconds}.png`;
        const dlButtonText = document.getElementById('dlButtonText');
        const downloadIcon = document.getElementById('downloadIcon');
        if (dlButtonText && downloadIcon) {
            dlButtonText.style.display = 'none';
            downloadIcon.style.display = 'block';
            setTimeout(() => {
                isDownloadAllowed = true;
                dlButtonText.style.display = 'block';
                downloadIcon.style.display = 'none';
            }, 5000);
        }
        try {
            const response = yield fetch(url);
            const blob = yield response.blob();
            const link = document.createElement('a');
            link.id = 'polaroidLink';
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
        }
        catch (error) {
            console.error('Error downloading the image:', error);
        }
    });
}
