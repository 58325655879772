import '../public/style.css';
import { downloadImage } from "./downloadManager";
import { loadingManager, activateDegradedMode } from "./loadingManager";
console.log('version 2');
const params = new URLSearchParams(window.location.search);
const token = params.get('token') || null;
const teamParam = params.get('team');
const team = teamParam == 'alice' || teamParam == 'queen' ? teamParam : 'alice';
export let photoImg = token + '.png';
export let rawParams = 'l-image,i-' + team + '.png,w-1080,h-1920,l-end';
console.log(rawParams);
console.log(token);
console.log(team);
document.addEventListener('DOMContentLoaded', function () {
    if (token) {
        loadingManager();
    }
    else {
        activateDegradedMode(false);
    }
    const dlButton = document.getElementById('dlButton');
    const polaroidImg = document.getElementById('polaroid');
    const gameButton = document.getElementById('gameButton');
    const showButton = document.getElementById('showButton');
    if (dlButton && polaroidImg && showButton) {
        dlButton.addEventListener('click', () => {
            downloadImage(polaroidImg.src);
        });
    }
    else {
        console.error('Either dlButton or polaroid image not found.');
    }
    if (gameButton) {
        gameButton.addEventListener('click', () => {
            openDisneyShowWebsite('https://www.jeux-galeries-lafayette.com/disneyland-paris?utm_source=photocall&utm_medium=jeu&utm_campaign=glxdisney');
        });
    }
    else {
        console.error('showButton not found.');
    }
    if (showButton) {
        showButton.addEventListener('click', () => {
            openDisneyShowWebsite('https://www.disneylandparis.com/fr-fr/divertissements/parc-walt-disney-studios/spectacle-alice-et-la-reine-de-coeur/');
        });
    }
    else {
        console.error('showButton not found.');
    }
});
function openDisneyShowWebsite(url) {
    window.open(url, '_blank');
}
//URL WORKING
//https://ik.imagekit.io/933g1u6gm/tr:h-1430,w-1080,l-image,i-alice.png,l-end/09cf42fc6390cd817533dd3dc62f273cb2474e2759e4fc06aeeb46abf970c006.png
