import ImageKit from "imagekit-javascript";
import { photoImg, rawParams } from "./main";
var cancelReload;
let loaderTime = 3000; //ms
let isDegradedMode = false;
let isImageLoaded = false;
let loaderContainer;
let statusText;
let pageContainer;
let footImg;
let degradedModeSection;
let degradedModeStatusText;
let degradedModeMessages = [
    `Pas de photo !<br><br>La connexion a échoué. Veuillez réessayer plus tard, peut-être après une tasse de thé.`,
    "Pas de photo !<br><br>Veuillez réessayer plus tard, quand la connexion internet aura retrouvé son chemin.",
];
document.addEventListener('DOMContentLoaded', function () {
    loaderContainer = document.getElementById('loaderContainer');
    pageContainer = document.getElementById('pageContainer');
    footImg = document.getElementById('foot');
    statusText = document.getElementById('statusText');
    degradedModeSection = document.getElementById('degradedModeSection');
    degradedModeStatusText = document.getElementById('degradedModeStatus');
});
export function loadingManager() {
    var imageURL = searchImageURL();
    console.log('VERIFY IF PHOTO EXIST');
    if (imageURL) {
        console.log('imageURL');
        console.log(imageURL);
        logImageLoaded(imageURL);
    }
}
function logImageLoaded(url) {
    const img = new Image();
    img.src = url;
    img.onload = () => {
        console.log('Image loaded successfully.');
        isImageLoaded = true;
        setPolaroidSource(url);
        removeLoadingPage();
    };
    img.onerror = () => {
        console.error('Error loading image.');
        if (!isDegradedMode)
            cancelReload = retry();
    };
}
function retry() {
    var interval = setInterval(() => {
        loadingManager();
        cancelReload();
    }, 1000);
    return () => clearInterval(interval);
}
var imagekit = new ImageKit({
    urlEndpoint: "https://ik.imagekit.io/933g1u6gm"
});
function searchImageURL() {
    try {
        var imageURL = imagekit.url({
            path: photoImg,
            urlEndpoint: "https://ik.imagekit.io/933g1u6gm",
            transformation: [{
                    "height": "1920",
                    "width": "1080",
                    "raw": rawParams
                }]
        });
        return imageURL;
    }
    catch (error) {
        console.log(error);
    }
}
function setPolaroidSource(url) {
    const polaroidImg = document.getElementById('polaroid');
    if (polaroidImg) {
        polaroidImg.src = url;
    }
    else {
        console.error('Element with ID "polaroid" not found.');
    }
}
function removeLoadingPage() {
    if (pageContainer)
        pageContainer.style.display = 'flex';
    setTimeout(() => {
        if (loaderContainer && pageContainer) {
            loaderContainer.style.display = 'none';
        }
    }, loaderTime);
}
function measureTime() {
    let startTime = Date.now(); // Record the start time
    // Check the time after 3 seconds
    setTimeout(function () {
        let currentTime = Date.now();
        let elapsedTime = (currentTime - startTime) / 1000; // Convert to seconds
        if (elapsedTime > 3) {
            loaderTime = 0;
            console.log('Time exceeded 3 seconds, variable set to:', loaderTime);
            // You can add any other actions you want to perform here
        }
    }, 3000); // 3000 milliseconds = 3 seconds
    setTimeout(() => {
        updateStatusText();
    }, 20000);
    setTimeout(() => {
        activateDegradedMode(true);
    }, 50000);
}
measureTime();
export function activateDegradedMode(isTokenExist) {
    if (isImageLoaded)
        return;
    isDegradedMode = true;
    removeLoadingPage();
    removeDlSection();
    if (isTokenExist) {
        updateDegradedModeText();
        showDegradedStatus();
    }
    else {
        if (footImg)
            footImg.style.marginTop = '65vw';
    }
    console.log('DEGRADED MODE');
}
function updateStatusText() {
    if (isImageLoaded)
        return;
    if (statusText)
        statusText.textContent = 'Encore un petit moment...';
}
function removeDlSection() {
    const dlSection = document.getElementById('dlSection');
    if (dlSection)
        dlSection.style.display = 'none';
}
function updateDegradedModeText() {
    let index = Math.floor(Math.random() * 2);
    if (degradedModeStatusText)
        degradedModeStatusText.innerHTML = degradedModeMessages[index];
}
function showDegradedStatus() {
    if (degradedModeSection)
        degradedModeSection.style.display = 'flex';
}
